import React from 'react';
import ReactDOM from "react-dom";
import App from "./App"
import { Provider } from "react-redux";
import Reducers from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "assets/css/style.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
const reducer=Reducers()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reducer.store}>
      <PersistGate loading={null} persistor={reducer.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

