const config={
    APP_NAME:"Bendo Event",
    APP_DESC:"Take your business to next level",
    APP_KEY:"",
    DEFAULT_LANG:"en",
    DASHBOARD_PATH:"dashboard",
    host: "https://apis.bendoevent.com",
    Autorisation:"dXBhc29kZXNpOiRtNmJ2aHNkaHZzZA==",
    devise:'FCFA',
    roles:{
        personnal:"PERSONNAL_CUSTOMER_ROLE",
        company:"COMPANY_CUSTOMER_ROLE",
        admin:"ADMIN_ROLE",
    },
    KKPAY_PUBLIC:"3927230a05e85f32127c19995f236031fab7fddd",
    retraitMethod:{
        mtn_momo:"MTN MOBILE MONEY (BENIN)",
        moov_flooz:"FLOOZ (BENIN)",
    }
}

export default config
