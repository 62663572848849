import React from "react";
import { ImSpinner5 } from "react-icons/im";

export default class Loader extends React.Component {
    
    render() {
        return (
            <div className="myLoader">
                <div className={"bg-white d-flex justify-content-center align-items-center w-100 h-100 "+(this.props.opacity?"opacity-3":"")} >
                    <ImSpinner5 size={50} color={"#0c0c63"} className="icon-spin"/>
                </div>
            </div>
        );
    }
};