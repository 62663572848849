import config from '../../constants/config'
const initialState = { currentlanguage: config.DEFAULT_LANG }

function setLang(state = initialState, action) {
    let nextState
    switch (action.type) {
        case "TOGGLE_LANGUAGE":
            nextState = {
                ...state,
                currentlanguage: action.value
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default setLang