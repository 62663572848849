
const initialState = { session: false }

function toggleSession(state = initialState, action) {
    let nextState

    switch (action.type) {
        case "SAVE_SESSION":
            var value = action.value
            //value.sessionespdate = Math.floor(Date.now() / 1000) + 3600
            nextState = {
                ...state,
                session: value
            }
            return nextState || state
            break;
        case "DELETE_SESSION":
            nextState = {
                ...state,
                session: false
            }
            return nextState || state
            break;
        case "UPDATE_SESSION":
            nextState = {
                ...state,
                session: { ...state.session},
            }
            return nextState || state
            break;
        default:
            return state
            break;
    }
}

export default toggleSession