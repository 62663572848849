import React, { Suspense, lazy } from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loader from "components/Loader/Loader"
import MyHelmet from './components/General/Helmet'
import config from './constants/config'
const AdminLayout = lazy(() => import('./layouts/Admin.js'));
const Login = lazy(() => import('./views/Login.js'));
const RecoverPassword = lazy(() => import('./views/RecoverPassword.js'));
const Register = lazy(() => import('./views/Register.js'));
const Applications = lazy(() => import('./views/Applications.js'));
const BuyCredit = lazy(() => import('./views/BuyCredit.js'));
const WithdrawGain = lazy(() => import('./views/WithdrawGain.js'));
const Settings =lazy(()=>import('./views/Settings.js'))
const hist = createBrowserHistory();

function App(props) {

  return (
    <div>
    <MyHelmet title={config.APP_NAME} description={config.APP_DESC} />
    <Router history={hist}>
        <Suspense fallback={<Loader/>}>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/create-account" component={Register} />
                <Route path="/settings" component={Settings} />
                <Route path="/buy-credit" component={BuyCredit} />
                <Route path="/withdraw-gains" component={WithdrawGain} />
                <Route path="/recoverpassword/:hash" component={RecoverPassword} />
                <Route exact path="/applications" component={Applications} />
                <Route path="/applications/:app_tag" render={(props) => <AdminLayout {...props} />} />
                <Redirect to="/applications" />
            </Switch>
        </Suspense>
    </Router>
    </div>
  ) 
}



export default App